import React from 'react'
import { graphql } from 'gatsby'

import Page from '../../templates/Page'

const AgbDe = () => (
  <Page title="AGB">
    <a href="https://nighttec.net/wp-content/uploads/2021/03/Allgemeine-Geschaftsbedingungen.pdf">
      PDF herunterladen
    </a>
    <p>
      <strong>Allgemeine Geschäftsbedingungen</strong>
    </p>
    <p></p>
    <p>
      <strong>Vertragspartner</strong>
    </p>
    <p>
      Auf Grundlage dieser Allgemeinen Geschäftsbedingungen (AGB) kommt zwischen
      dem Kunden und NightTec
    </p>
    <p>
      Vertreten durch Tom Heinze
      <br />
      Blankenburger Straße 45
      <br /> 13156 Berlin
      <br />
      Tel: +49 176 462 921 69
      <br />
      E-Mail: Info@NightTec.net
    </p>
    <p>Handelsregister: [Handelsregister]</p>
    <p>Handelsregisternummer: [Handelsregisternummer]</p>
    <p>Umsatzsteuer-Identifikationsnummer: DE317287664</p>
    <p>nachfolgend Anbieter genannt, der Vertrag zustande.</p>
    <p>
      <strong>Vertragsgegenstand</strong>
    </p>
    <p>
      Durch diesen Vertrag wird der Verkauf von neue Waren und Dienstleistungen
      aus dem Bereich/den Bereichen Nachtsichtgeräte über den Online-Shop des
      Anbieters geregelt. Wegen der Details des jeweiligen Angebotes wird auf
      die Produktbeschreibung der Angebotsseite verwiesen.
    </p>
    <p>
      Alle Käufer, die exportkontrollierte Produkte bestellen, müssen NightTec
      einen ausgefüllten EUC vorlegen um ihre Einkäufe zu validieren. Zum
      Zeitpunkt der Bestellung wird der NightTec Kundendienst den Käufer
      kontaktieren, um das EUC-Formular anzufordern. Bei Nichtbeachtung wird die
      Bestellung sofort ungültig. Der Bestellbetrag wird zurückerstattet und die
      Bestellung abgelehnt.
    </p>
    <p>Die EUC finden Sie hier.</p>
    <p>
      <strong>Vertragsschluss</strong>
    </p>
    <p>
      Der Vertrag kommt im elektronischen Geschäftsverkehr über das Shop-System
      oder über andere Fernkommunikationsmittel wie Telefon und E-Mail zustande.
      Dabei stellen die dargestellten Angebote eine unverbindliche Aufforderung
      zur Abgabe eines Anbgebots durch die Kundenbestellung dar, das der
      Anbieter dann annehmen kann. Der Bestellvorgang zum Vertragsschluss
      umfasst im Shop-System folgende Schritte:
    </p>
    <p></p>
    <p>
        Auswahl des Angebots in der gewünschten Spezifikation (Größe, Farbe,
      Anzahl)
    </p>
    <p>  Einlegen des Angebots in den Warenkorb</p>
    <p>  Betätigen des Buttons 'bestellen'</p>
    <p>  Eingabe der Rechnungs- und Lieferadresse</p>
    <p>  Auswahl der Bezahlmethode</p>
    <p>  Überprüfung und Bearbeitung der Bestellung und aller Eingaben</p>
    <p>  Betätigen des Buttons 'kostenpflichtig bestellen'</p>
    <p>  Bestätigungsmail, dass Bestellung eingegangen ist</p>
    <p></p>
    <p>
      Bestellungen können neben dem Shop-System auch über
      Fernkommunikationsmittel (Telefon/E-Mail) aufgegeben werden, wodurch der
      Bestellvorgang zum Vertragsschluss folgende Schritte umfasst:
    </p>
    <p></p>
    <p>  Anruf bei der Bestellhotline / Übersendung der Bestellmail</p>
    <p>  Bestätigungsmail, dass Bestellung eingegangen ist</p>
    <p></p>
    <p>Mit der Zusendung der Bestellbestätigung kommt der Vertrag zustande.</p>
    <p>
      <strong>Vertragsdauer</strong>
    </p>
    <p>Der Vertrag wird auf unbestimmte Zeit geschlossen.</p>
    <p>
      <strong>Preise, Versandkosten, Rücksendekosten</strong>
    </p>
    <p>
      Alle Preise sind Endpreise und enthalten die gesetzliche Umsatzsteuer.
      Neben den Endpreisen fallen je nach Versandart weitere Kosten an, die vor
      Versendung der Bestellung angezeigt werden. Besteht ein Widerrufsrecht und
      wird von diesem Gebraucht gemacht, trägt der Kunde die Kosten der
      Rücksendung.
    </p>
    <p>
      <strong>Zahlungsbedingungen</strong>
    </p>
    <p>
      Der Kunde hat ausschließlich folgende Möglichkeiten zur Zahlung:
      Vorabüberweisung, Zahlungsdienstleister (PayPal), Kreditkarte. Weitere
      Zahlungsarten werden nicht angeboten und werden zurückgewiesen. Der
      Rechnungsbetrag ist nach Zugang der Rechnung, die alle Angaben für die
      Überweisung enthält und mit E-Mail verschickt wird, auf das dort
      angegebene Konto vorab zu überweisen. Bei Verwendung eines
      Treuhandservice/ Zahlungsdienstleisters ermöglicht es dieser dem Anbieter
      und Kunden, die Zahlung untereinander abzuwickeln. Dabei leitet der
      Treuhandservice/ Zahlungsdienstleister die Zahlung des Kunden an den
      Anbieter weiter. Weitere Informationen erhalten Sie auf der Internetseite
      des jeweiligen Treuhandservices/ Zahlungsdienstleisters. Bei Zahlung über
      Kreditkarte muss der Kunde der Karteninhaber sein. Die Belastung der
      Kreditkarte erfolgt nach Versand der Ware. Der Kunde ist verpflichtet
      innerhalb von 14 Tagen nach Erhalt der Rechnung den ausgewiesenen Betrag
      auf das auf der Rechnung angegebene Konto einzuzahlen oder zu überweisen.
      Die Zahlung ist ab Rechnungsdatum ohne Abzug fällig. Der Kunde kommt erst
      nach Mahnung in Verzug.
    </p>
    <p>
      <strong>Lieferbedingungen</strong>
    </p>
    <p>
      Die Ware wird umgehend nach bestätigtem Zahlungseingang versandt. Der
      Versand erfolgt durchschnittlich spätestens nach 2 Tagen. Der Unternehmer
      verpflichtet sich zur Lieferung am 7 Tag nach Bestelleingang falls nicht
      anders abgesprochen. Die Regellieferzeit beträgt 2 Tage, es sei denn es
      handelt sich um eine Bestellung die erst gefertigt werden muss. Der
      Unternehmer wird Ihnen in der Regel die Lieferzeit vorab nennen. Der
      Anbieter versendet die Bestellung entweder aus eigenem Lager, sobald die
      gesamte Bestellung dort vorrätig ist oder die Bestellung wird vom
      Hersteller verschickt, sobald die gesamte Bestellung dort vorrätig ist.
      Der Kunde wird über Verzögerungen umgehend informiert.
    </p>
    <p>
      <strong>Gewährleistung</strong>
    </p>
    <p>
      Verbrauchern steht für die angebotenen Leistungen ein gesetzliches
      Mängelhaftungsrecht nach den einschlägigen Vorschriften des Bürgerlichen
      Gesetzbuch (BGB) zu. Sofern hiervon abgewichen wird, richtet sich die
      Gewährleistung nach den hierzu verfassten Regelungen in den Allgemeinen
      Geschäftsbedingungen (AGB). Ist der Kunde Unternehmer, wird für
      Gebrauchtwaren die Gewährleistung ausgeschlossen. Ist der Kunde
      Verbraucher, wird für gebrauchte Waren die Gewährleistungsfrist auf ein
      Jahr beschränkt. Dies gilt nicht für Schadensersatzansprüche des Kunden
      wegen Verletzung des Lebens, des Körpers, der Gesundheit oder wesentlicher
      Vertragspflichten, welche zur Erreichung des Vertragszieles
      notwendigerweise erfüllt werden müssen. Ebenso gilt dies nicht für
      Schadensersatzansprüche nach grob fahrlässiger oder vorsätzlicher
      Pflichtverletzung des Anbieters oder seines gesetzlichen Vertreters oder
      Erfüllungsgehilfen. Im Übrigen gelten die gesetzlichen Regelungen.
    </p>
    <p>
      <strong>Vertragsgestaltung</strong>
    </p>
    <p>
      Der Vertragstext wird vom Anbieter gespeichert. Der Kunde hat folgende
      Möglichkeit auf den gespeicherten Vertragstext zuzugreifen: Über das
      Shopsystem bei Bestellung. Dieser Bereich findet sich auf folgender Seite:
      www.NightTec.net. Der Kunde kann Fehler in der Eingabe während des
      Bestellvorganges korrigieren. Hierzu kann er folgendermaßen vorgehen: Über
      das Shopsystem bei Bestellung.
    </p>
    <p>
      <strong>Widerrufsrecht und Kundendienst</strong>
    </p>
    <p>
      <strong>Widerrufsbelehrung</strong>
    </p>
    <p>Widerrufsrecht</p>
    <p>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen.
    </p>
    <p>Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag,</p>
    <ul>
      <li>
        <p>
          Im Falle eines Kaufvertrags: an dem Sie oder ein von Ihnen benannter
          Dritter, der nicht der Beförderer ist, die letzte Ware in Besitz
          genommen haben bzw. hat.
        </p>
      </li>
    </ul>
    <ul>
      <li>
        <p>
          Im Falle einer Vertrags über mehrere Waren, die der Verbraucher im
          Rahmen einer einheitlichen Bestellung bestellt hat und die getrennt
          geliefert werden: an dem Sie oder ein von Ihnen benannter Dritter, der
          nicht Beförderer ist, die letzte Ware in Besitz genommen haben bzw.
          hat.
        </p>
      </li>
      <li>
        <p>
          Im Falle eines Vertrags über die Lieferung einer Ware in mehreren
          Teilsendungen oder Stücken: an dem Sie oder ein von Ihnen benannter
          Dritter, der nicht Beförderer ist, die letzte Teilsendung oder das
          letzte Stück in Besitz genommen haben bzw. hat.
        </p>
      </li>
      <li>
        <p>
          Im Falle eines Vertrages zur regelmäßigen Lieferung von Waren über
          einen festgelegten Zeitraum hinweg: an dem Sie oder ein von Ihnen
          benannter Dritter, der nicht Beförderer ist, die erste Ware in Besitz
          genommen haben bzw. hat.
        </p>
      </li>
    </ul>
    <p></p>
    <p>
      Beim Zusammentreffen mehrerer Alternativen ist der jeweils letzte
      Zeitpunkt maßgeblich.
    </p>
    <p>
      Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (NightTec, Tom Heinze,
      Blankenburger Straße 45, 13156 Berlin +49 176 462 921 69
      info@nighttec.net) mittels einer eindeutigen Erklärung (z.B. ein mit der
      Post versandter Brief, Telefax, oder E-Mail) über Ihren Entschluss, diesen
      Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
    </p>
    <p>
      Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über
      die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    </p>
    <p>
      <strong>Folgen des Widerrufs</strong>
    </p>
    <p>
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben unverzüglich und spätestens binnen vierzehn
      Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf
      dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden
      wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion
      eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes
      vereinbart. Wir können die Rückzahlung verweigern, bis wir die Waren
      wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass
      Sie die Waren zurückgesandt haben, je nachdem, welches der frühere
      Zeitpunkt ist.
    </p>
    <p></p>
    <p>
      Alle Nachtsichtgeräte werden auf Bestellung gefertigt. Die ausgewählten
      Waren sind nicht auf Lager und werden speziell für den bei der Bestellung
      angegebenen Käufer bestellt. Bitte stellen Sie sicher, dass Sonderwünsche
      und Spezifikationen in Ihrer Auftragsbestätigung oder Rechnung vermerkt
      sind.
    </p>
    <p></p>
    <p>
      Wenn der Käufer aus irgendeinem Grund eine Bestellung ganz oder teilweise
      für eine auf Bestellung gefertigte Sache storniert und wenn nichts anderes
      schriftlich vereinbart ist, wird eine Stornierungsgebühr in Höhe von 25%
      des Rechnungswerts vom Käufer bezahlt oder von der Rückerstattung
      abgezogen.
    </p>
    <p>
      Wenn der Käufer aus irgendeinem Grund eine Bestellung ganz oder teilweise
      für einen nicht auftragsbezogenen Artikel storniert und wenn nichts
      anderes schriftlich vereinbart ist, wird eine Stornierungsgebühr in Höhe
      von 15% des Rechnungswerts vom Käufer gezahlt oder von der Rückerstattung
      abgezogen.
    </p>
    <p>
      Bei der Bestellung einer oder mehrerer Röhren gilt folgende Besonderheit.
      Röhren können, solange nicht aus der OVP geöffnet, mit einer
      Stornierungsgebühr (siehe auf Bestellung gefertigte Sache) in Höhe von 25%
      des Rechnungswerts storniert werden. Sollten sie vom Kunden geöffnet
      werden entfällt jegliche Rücknahme.
    </p>
    <p></p>
    <p>Stornierungsgebühren bei gemieteten Geräten lauten wie folgt:</p>
    <p>
      Mehr als 14 Tage vor dem ersten Tag des Events: 0%
      <br />
      7-14 Tage vor dem ersten Tag des Events: 50%
      <br />
      0-7 Tage vor dem ersten Tag des Events: 90%
    </p>
    <p>
      Ein Kunde der bei einer Anmietung keinen gültigen Ausweis vorweisen kann
      hat keinen Anspruch auf das Gerät.
    </p>
    <p>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
      vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
      Vertrags unterrichten, an
      <br />
      <br /> NightTec,
      <br /> Tom Heinze,
      <br /> Blankenburger Straße 45,
      <br /> 13156 Berlin
      <br /> +49 176 462 921 69
      <br />
      info@nighttec.net
      <br />
      <br /> uns zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn
      Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden.
    </p>
    <p>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</p>
    <p>
      Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
      dieser Wertverlust auf einen zur Prüfung der Beschaffenheit, Eigenschaften
      und Funktionsweise der Waren nicht notwendigen Umgang mit ihnen
      zurückzuführen ist.
    </p>
    <p>Ende der Widerrufsbelehrung</p>
    <p>Export/Import und Zoll</p>
    <p>
      Der Verkauf und die Verwendung von Waren und Software, die von NightTec
      geliefert werden, unterliegen jederzeit den Exportkontrollgesetzen und
      -bestimmungen Japans, der Vereinigten Staaten von Amerika, der
      Europäischen Union und ihrer Mitgliedstaaten sowie deren Änderungen. Der
      Käufer wird solche Waren oder Software nur in dem vom Käufer schriftlich
      angegebenen und auf der Rechnung von NightTec angegebenen endgültigen
      Bestimmungsland veräußern. Der Käufer wird solche Waren oder Software oder
      Teile davon, einschließlich technischer Daten oder des direkten Produkts,
      nicht exportieren was gegen die geltenden Vorschriften Japans, der
      Vereinigten Staaten von Amerika, Kanadas, Australiens, Neuseelands,
      Norwegens, der Schweiz, Großbritannien und Europäische Union oder ihren
      Mitgliedstaaten verstößt.
    </p>
    <p>
      Jeder Kunde ist dazu angewiesen sich über das geltende Recht seines Landes
      zu informieren. NightTec kann bei Verstößen gegen das geltende Recht des
      jeweiligen Landes nicht haftbar gemacht werden.
    </p>
    <p>
      <strong>Haftungsausschluss</strong>
    </p>
    <p>
      Schadensersatzansprüche des Kunden sind ausgeschlossen, soweit sich aus
      den nachfolgenden Gründen nicht etwas anderes ergibt. Dies gilt auch für
      den Vertreter und Erfüllungsgehilfen des Anbieters, falls der Kunde gegen
      diese Ansprüche auf Schadensersatz erhebt. Ausgenommen sind
      Schadensersatzansprüche des Kunden wegen Verletzung des Lebens, des
      Körpers, der Gesundheit oder wesentlicher Vertragspflichten, welche zur
      Erreichung des Vertragszieles notwendigerweise erfüllt werden müssen.
      Ebenso gilt dies nicht für Schadensersatzansprüche nach grob fahrlässiger
      oder vorsätzlicher Pflichtverletzung des Anbieters oder seines
      gesetzlichen Vertreters oder Erfüllungsgehilfen.
    </p>
    <p>
      <strong>Abtretungs- und Verpfändungsverbot</strong>
    </p>
    <p>
      Ansprüche oder Rechte des Kunden gegen den Anbieter dürfen ohne dessen
      Zustimmung nicht abgetreten oder verpfändet werden, es sei denn der Kunde
      hat ein berechtigtes Interesse an der Abtretung oder Verpfändung
      nachgewiesen.
    </p>
    <p>
      <strong>Sprache, Gerichtsstand und anzuwendendes Recht</strong>
    </p>
    <p>
      Der Vertrag wird in deutsch, englisch abgefasst. Die weitere Durchführung
      der Vertragsbeziehung erfolgt in deutsch, englisch. Es findet
      ausschließlich das Recht der Bundesrepublik Deutschland Anwendung. Für
      Verbraucher gilt dies nur insoweit, als dadurch keine gesetzlichen
      Bestimmungen des Staates eingeschränkt werden, in dem der Kunde seinen
      Wohnsitz oder gewöhnlichen Aufenthalt hat. Gerichtsstand ist bei
      Streitigkeiten mit Kunden, die kein Verbraucher, juristische Person des
      öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen sind, Sitz
      des Anbieters.
    </p>
    <p>
      <strong>Datenschutz</strong>
    </p>
    <p>
      Im Zusammenhang mit der Anbahnung, Abschluss, Abwicklung und
      Rückabwicklung eines Kaufvertrages auf Grundlage dieser AGB werden vom
      Anbieter Daten erhoben, gespeichert und verarbeitet. Dies geschieht im
      Rahmen der gesetzlichen Bestimmungen. Der Anbieter gibt keine
      personenbezogenen Daten des Kunden an Dritte weiter, es sei denn, dass er
      hierzu gesetzlich verpflichtet wäre oder der Kunde vorher ausdrücklich
      eingewilligt hat. Wird ein Dritter für Dienstleistungen im Zusammenhang
      mit der Abwicklung von Verarbeitungsprozessen eingesetzt, so werden die
      Bestimmungen des Bundesdatenschutzgesetzes eingehalten. Die vom Kunden im
      Wege der Bestellung mitgeteilten Daten werden ausschließlich zur
      Kontaktaufnahme innerhalb des Rahmens der Vertragsabwicklung und nur zu
      dem Zweck verarbeitet, zu dem der Kunde die Daten zur Verfügung gestellt
      hat. Die Daten werden nur soweit notwendig an das Versandunternehmen, das
      die Lieferung der Ware auftragsgemäß übernimmt, weitergegeben. Die
      Zahlungsdaten werden an das mit der Zahlung beauftragte Kreditinstitut
      weitergegeben. Soweit den Anbieter Aufbewahrungsfristen handels- oder
      steuerrechtlicher Natur treffen, kann die Speicherung einiger Daten bis zu
      zehn Jahre dauern. Während des Besuchs im Internet-Shop des Anbieters
      werden anonymisierte Daten, die keine Rückschlüssen auf personenbezogene
      Daten zulassen und auch nicht beabsichtigen, insbesondere IP-Adresse,
      Datum, Uhrzeit, Browsertyp, Betriebssystem und besuchte Seiten,
      protokolliert. Auf Wunsch des Kunden werden im Rahmen der gesetzlichen
      Bestimmungen die personenbezogenen Daten gelöscht, korrigiert oder
      gesperrt. Eine unentgeltliche Auskunft über alle personenbezogenen Daten
      des Kunden ist möglich. Für Fragen und Anträge auf Löschung, Korrektur
      oder Sperrung personenbezogener Daten sowie Erhebung, Verarbeitung und
      Nutzung kann sich der Kunde an folgende Adresse wenden:
      <br />
      <br /> NightTec,
      <br /> Tom Heinze,
      <br /> Blankenburger Straße 45,
      <br /> 13156 Berlin
      <br /> +49 176 462 921 69
      <br /> info@nighttec.net.
    </p>
  </Page>
)

export default AgbDe

export const query = graphql`
  query ($language: String!) {
    i18nLocales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
